import { Paper, Typography, withStyles } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Highlights from './highlights';

export const paperStyle = theme => ({
  padding: theme.spacing.unit * 2,
  marginTop: theme.spacing.unit * 2,
  marginBottom: theme.spacing.unit * 2,
  color: theme.palette.text.secondary,
})

const styles = theme => ({
  paper: paperStyle(theme),
  logo: {
    maxWidth: '70px',
    maxHeight: '70px',
    float: 'right',
  },
})

const JobHistory = props => {
  let { classes } = props
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/jobs/"}},
            sort: { order: ASC, fields: [fileAbsolutePath]}) {
            edges {
              node {
                frontmatter {
                  startDate
                  endDate
                  company
                  department
                  position
                  highlights {
                    title
                    image {
                      publicURL
                    }
                    link
                  }
                  logo {
                    publicURL
                  }
                }
               html
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark }) => {
        let jobs = allMarkdownRemark.edges
        return (
          <>
            {jobs.map((edge, i) => {
              let job = edge.node;
              return (
                <Paper className={classes.paper} key={i}>
                  {job.frontmatter.logo ? <img className={classes.logo} src={job.frontmatter.logo.publicURL} alt={job.frontmatter.company + ' logo'} /> : null}
                  <Typography align="left" variant="caption" gutterBottom>
                    {job.frontmatter.startDate} - {job.frontmatter.endDate}
                  </Typography>
                  <Typography align="left" variant="h5">
                    {job.frontmatter.company}
                  </Typography>
                  <Typography align="left" variant="subtitle1">
                    {job.frontmatter.department}
                  </Typography>
                  <Typography align="left" variant="h6" gutterBottom>
                    {job.frontmatter.position}
                  </Typography>
                  <Typography variant="body1" gutterBottom >
                    <div dangerouslySetInnerHTML={{ __html: job.html }} />
                  </Typography >
                  {job.frontmatter.highlights ? <Highlights highlights={job.frontmatter.highlights} /> : null}
                </Paper>
              )
            })}
          </>
        )
      }}
    />
  )
}

// @ts-ignore
export default withStyles(styles)(JobHistory)
