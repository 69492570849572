import { Divider, Paper, Typography, withStyles } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { paperStyle } from './job-history';

const styles = theme => ({
   paper: paperStyle(theme),
   divider: {
      marginBottom: '10px',
      marginTop: '10px'
   }
})

const Education = props => {
   let { classes } = props
   return (
      <StaticQuery
         query={graphql`
        query {
          allMarkdownRemark(
             filter: {fileAbsolutePath: {regex: "/education/"}},
             sort: { order: ASC, fields: [fileAbsolutePath]}) {
            edges {
              node {
                frontmatter {
                  start
                  finish
                  place
                  title
                  course
                  detail
                }
              }
            }
          }
        }
      `}
         render={({ allMarkdownRemark }) => {
            let educations = allMarkdownRemark.edges

            return (
               <Paper className={classes.paper}>
                  {educations.map((education, i) => {
                     return <div key={i}><Typography align="left" variant="caption" gutterBottom >
                        {education.node.frontmatter.start}{education.node.frontmatter.finish ? ' - ' + education.node.frontmatter.finish : null}
                     </Typography>
                        <Typography align="left" variant="h5">
                           {education.node.frontmatter.place}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                           {education.node.frontmatter.title}
                        </Typography>
                        <Typography align="left" variant="subtitle1">
                           {education.node.frontmatter.course}{education.node.frontmatter.detail ? " - " + education.node.frontmatter.detail : null}
                        </Typography>
                        {i < educations.length - 1 ? <Divider className={classes.divider} /> : null}
                     </div >
                  })}
               </Paper>

            )
         }}
      />
   )
}

// @ts-ignore
export default withStyles(styles)(Education)
