import { CardActions } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  card: {
    margin: theme.spacing.unit,
    color: theme.palette.text.secondary,
    maxWidth: 250,
    minWidth: 250,
    height: 312
  },
  media: {
    height: 140,
  },
})

function Example(props) {
  const { classes } = props

  return (
    <Card className={classes.card}>
      <CardActionArea href={props.example.link}>
        <CardMedia className={classes.media} image={props.example.image.publicURL} title="Contemplative Reptile" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.example.title}
          </Typography>
          <Typography gutterBottom component="p">{props.example.description}</Typography>
          <Typography color="textSecondary">{props.example.tech}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>


      </CardActions>
    </Card>
  )
}

Example.propTypes = {
  classes: PropTypes.object.isRequired,
  example: PropTypes.object.isRequired,
}

export default withStyles(styles)(Example)
