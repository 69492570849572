import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import ImageOrWebM from './imageOrWebM';

const styles = theme => ({
  root: {
    width: '100%',
  },
  images: {
    width: '100%',
    height: '100%',
    padding: '20px',
  },
})

class Hightlights extends React.Component {
  state = {
    expanded: null,
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  render() {
    const { classes } = this.props
    const { expanded } = this.state

    return (
      <div className={classes.root}>
        {this.props.highlights.map((highlight, i) => {
          return (
            <ExpansionPanel key={i} expanded={highlight.image ? expanded === `panel${i}` : false} onChange={this.handleChange(`panel${i}`)}>
              <ExpansionPanelSummary expandIcon={highlight.image ? <ExpandMoreIcon /> : null}>
                <a href={highlight.link}><Typography>{highlight.title}</Typography></a>
              </ExpansionPanelSummary>
              {highlight.image ? (
                <ExpansionPanelDetails>
                  <ImageOrWebM className={classes.images} publicURL={highlight.image.publicURL} alt={'Highlight'} />
                </ExpansionPanelDetails>
              ) : null}
            </ExpansionPanel>
          )
        })}
      </div>
    )
  }
}

Hightlights.propTypes = {
  classes: PropTypes.object.isRequired,
  highlights: PropTypes.array.isRequired,
}

export default withStyles(styles)(Hightlights)
