import PropTypes from 'prop-types';
import React from 'react';

const ImageOrWebM = props => {
   if (props.publicURL.includes('.webm')) {
      return (<video autoPlay controls className={props.className}>
         <source src={props.publicURL} type="video/webm" />
         Your browser does not support this video. You should probably update it.
         </video>)

   } else {
      return <img className={props.className} src={props.publicURL} alt={props.alt} />
   }
}

ImageOrWebM.propTypes = {
   className: PropTypes.string.isRequired,
   publicURL: PropTypes.string.isRequired,
   alt: PropTypes.string.isRequired,
}

// @ts-ignore
export default ImageOrWebM
