import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import Education from '../components/education';
import JobHistory from '../components/job-history';
import Layout from '../components/layout';
import Profile from '../components/profile';
import Skills from '../components/skills';
import Tech from '../components/tech';
import WorkExamples from '../components/work-examples';

const styles = theme => ({
})

function IndexPage(props) {
  return (
    <Layout>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={3}>
          <Profile />
          <Tech />
          <Education />
          <Skills />
        </Grid>
        <Grid item xs={12} sm={9}>
          <JobHistory />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-around" alignItems="center">
        <WorkExamples />
      </Grid>
    </Layout>
  )
}

// @ts-ignore
export default withStyles(styles)(IndexPage)
