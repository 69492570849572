import { withStyles } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Example from './example';

const styles = theme => ({})

const JobHistory = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/example/"}}) {
            edges {
              node {
                frontmatter {
                  title
                  link
                  image {
                    publicURL
                  }
                  description
                  tech
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark }) => {
        let examples = allMarkdownRemark.edges
        return (
          <>
            {examples.map((example, i) => {
              return <Example example={example.node.frontmatter} key={i} />
            })}
          </>
        )
      }}
    />
  )
}

// @ts-ignore
export default withStyles(styles)(JobHistory)
