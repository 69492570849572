import { Grid, Paper, withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import LogoMail from 'react-ionicons/lib/IosMail'
import LogoGithub from 'react-ionicons/lib/LogoGithub'
import LogoLinkedin from 'react-ionicons/lib/LogoLinkedin'
import LogoTwitter from 'react-ionicons/lib/LogoTwitter'
import { about, fullName, location, networks } from '../../site-config'
import { paperStyle } from './job-history'
import Photo from './photo'

function contactNetwork(name, logo) {
  let details = networks.filter(network => network.name === name)

  if (details.length === 1) {
    return logo
  } else {
    return null
  }
}

const styles = theme => ({
  root: {
    marginBottom: '20px',
  },
  paper: paperStyle(theme),
  logo: {
    maxWidth: '70px',
    maxHeight: '70px',
    float: 'right',
  },
})

const Profile = props => {
  const classes = props.classes

  let contactDetails = []

  contactDetails.push(contactNetwork('GitHub', <LogoGithub key="1" />))
  contactDetails.push(contactNetwork('Twitter', <LogoTwitter key="2" />))
  contactDetails.push(contactNetwork('Linkedin', <LogoLinkedin key="3" />))
  contactDetails.push(contactNetwork('email', <LogoMail key="4" />))

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuerys {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Paper className={classes.paper}>
          <Photo />
          <Typography align="left" variant="h4" gutterBottom>
            {fullName}
          </Typography>
          <Typography variant="h5" gutterBottom>{`${location.city}, ${location.countryCode}`}</Typography>
          <Typography className={classes.root} variant="body1" gutterBottom>
            {about}
          </Typography>
          <Grid container direction="row" justify="space-around" alignItems="center">
            {contactDetails}
          </Grid>
        </Paper>
      )}
    />
  )
}

// @ts-ignore
export default withStyles(styles)(Profile)

Profile.propTypes = {}
