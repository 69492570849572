import { Chip, Paper, withStyles } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { skills } from '../../site-config';
import { paperStyle } from './job-history';

const styles = theme => ({
  photo: {
    marginBottom: theme.spacing.unit * 2,
  },
  paper: paperStyle(theme),
  chipWrapper: {
    margin: theme.spacing.unit / 3.5,
  },
})

const Skills = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "face-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        let classes = props.classes
        return (
          <>
            {/* <Typography variant="h4" gutterBottom>
              <i className="fa fa-tasks ico" /> Skills
            </Typography> */}

            <Paper className={classes.paper}>
              {skills.map((skill, i) => {
                return <Chip className={classes.chipWrapper} label={skill} key={i} color="primary" />
              })}
            </Paper>
          </>
        )
      }}
    />
  )
}

// @ts-ignore
export default withStyles(styles)(Skills)
