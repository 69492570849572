import { withStyles } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const styles = theme => ({
  photo: {
    marginBottom: theme.spacing.unit * 2,
  },
});

const Photo = (props) => {
  const { classes } = props;
  return <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "face-image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img className={classes.photo} fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
};

// @ts-ignore
export default withStyles(styles)(Photo);
