module.exports = {
  fullName: 'Phil Alsford',
  about:
    'Hi! I can deliver frontend and backend applications. I like to be agile and move fast. I build things incrementally to deliver value earlier in the feedback loop. I solve problems with an inclusive team based approach.',
  label: 'Web Developer',
  email: 'phil.alsford@gmail.com',
  homepage: 'https://philalsford.com',
  location: {
    city: 'Wellington',
    countryCode: 'NZ',
  },
  skills: [
    'Continuous Integration',
    'Continuous Deployment',
    'TDD',
    'Automated testing',
    'Javascript',
    'NodeJS',
    'GraphQL',
    'Typescript',
    'ReactJS',
    'Spring Boot',
    'Kotlin',
    'Redux',
    'GIT',
    'Webpack',
    'Docker',
    '.NET',
    'CSS',
    'Sass',
    'Lambda',
    'ECS',
    'API Gateway',
    'S3',
    'SQS',
  ],
  networks: [
    {
      name: 'Twitter',
      username: 'phil_als',
      url: 'https://twitter.com/phil_als',
    },
    {
      name: 'email',
      username: 'phil.alsford@gmail.com',
      url: 'mailto:phil.alsford@gmail.com',
    },
    {
      name: 'Linkedin',
      username: 'philalsford',
      url: 'https://www.linkedin.com/in/philalsford/',
    },
    {
      name: 'GitHub',
      username: 'philals',
      url: 'https: //github.com/philals',
    },
  ],
}
