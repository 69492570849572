import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { paperStyle } from './job-history';

const styles = theme => ({
   techIcon: {
      maxWidth: 75,
      maxHeight: 75,
   },
   paper: paperStyle(theme),
   imageGrid: {
      textAlign: 'center'
   }
})

const Tech = props => {
   return (
      <StaticQuery
         query={graphql`
        query {
               allFile(filter: {absolutePath: {regex: "/tech/"}}) {
                  edges {
                     node {
                        extension
                        publicURL
                        name
                     }
                  }
               }
            }
      `}
         render={data => {
            let classes = props.classes
            return (
               <>
                  <Paper className={classes.paper}>
                     <Grid container spacing={24}
                        direction="row"
                        justify="space-evenly"
                        alignItems="center">
                        {data.allFile.edges.map((tech, i) => {
                           let techName = tech.node.name === 'dotNet' ? '.Net' : tech.node.name;
                           return <Grid item xs={6} sm={12} md={6} key={i} className={classes.imageGrid}>
                              <img className={classes.techIcon} src={tech.node.publicURL} alt={techName} />
                              <Typography align="center" variant="caption" gutterBottom>{techName}</Typography>
                           </Grid>
                        })}
                     </Grid>
                  </Paper>
               </>
            )
         }}
      />
   )
}

// @ts-ignore
export default withStyles(styles)(Tech)
